import * as THREE from 'three'
import { EventEmitter } from "./EventEmitter";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'


export class AssetManager extends EventEmitter {
    constructor(assets) {
        super()

        this.assets = assets

        this.loaders = null
        this.items = null
        this.loadingCount = assets.length
        this.loadedCount = 0

        this.init()
    }

    init() {
        this.items = {}

        this.loaders = {}
        this.loaders.texture = new THREE.TextureLoader()
        this.loaders.gltf = new GLTFLoader()
        this.loaders.fbx = new FBXLoader()
        this.loaders.exr = new EXRLoader()
        this.loaders.rgbe = new RGBELoader()
    }

    startLoading() {
        if (this.assets.length === 0) {
            this.trigger('ready')
            return
        }

        for (const asset of this.assets) {
            if (asset.type.toLowerCase() === "texture") {
                this.loaders.texture.load(asset.path,
                    (texture) => {
                        this.loadComplete(asset, texture)
                    },
                    () => {},
                    (event) => {
                        console.error('An error happened : ' + event);
                    }
                )
            }

            else if (asset.type.toLowerCase() === "exr") {
                this.loaders.exr.load(asset.path,
                    (texture) => {
                        texture.mapping = THREE.EquirectangularReflectionMapping
                        this.loadComplete(asset, texture)
                    },
                    () => {},
                    (event) => {
                        console.error('An error happened : ' + event);
                    }
                )
            }

            else if (asset.type.toLowerCase() === "hdr") {
                this.loaders.rgbe.load(asset.path,
                    (texture) => {
                        texture.mapping = THREE.EquirectangularReflectionMapping
                        this.loadComplete(asset, texture)
                    },
                    () => {},
                    (event) => {
                        console.error('An error happened : ' + event);
                    }
                )
            }

            else if (asset.type.toLowerCase() === "gltf") {
                this.loaders.gltf.load(asset.path, (gltf) => {
                    this.loadComplete(asset, gltf)
                })
            }

            else if (asset.type.toLowerCase() === "fbx") {
                this.loaders.fbx.load(asset.path, (fbx) => {
                    this.loadComplete(asset, fbx)
                })
            }
        }
    }

    loadComplete(asset, object) {
        console.log(`Load done [${asset.name}]`)
        this.items[asset.name] = object

        ++this.loadedCount

        this.trigger('progress', [{progress: this.loadedCount/this.loadingCount}])

        if (this.loadedCount === this.loadingCount) {
            this.trigger('ready')
        }
    }

    getItemNamesOfType(type) {
        return this.assets.filter(asset => asset.type.toLowerCase() === type.toLowerCase()).map(e => e.name)
    }

    getItem(name) {
        return this.items[name]
    }

    destroy() {
        this.assets = null
        this.loaders = null

        this.items.length = 0
        this.items = null
    }
}