import { App } from "../App"
import { AluminiumMaterial } from "../Materials/AluminiumMaterial"
import { EventEmitter } from "../Utils/EventEmitter"

import * as THREE from 'three'
import { Utils } from "../Utils/Utils"

export class Chocolate extends EventEmitter {

    constructor(hasLabel, hasAlu) {
        super()

        /**
         * Properties
         */
        this.app = null

        this.hasLabel = typeof hasLabel === "boolean" ? hasLabel : true
        this.hasAlu = typeof hasAlu === "boolean" ? hasAlu : true

        this.alu = null
        this.label = null
        this.chocolate = null

        this.clipAction = null
        this.animMixer = null


        this.init()
    }

    init() {
        this.app = new App()

        this.createInstance()
        // this.setupAnimation()
    }

    createInstance() {
        // this.instance = new THREE.Mesh(new THREE.BoxGeometry(0.01, 0.01, 0.01), new THREE.MeshPhongMaterial({color:"red"}))
        // return

        this.instance = this.app.assetManager.getItem('Chocolat_TEG_gltf').scene.clone()

        this.alu = this.instance.getObjectByName("Alu_LP")
        this.label = this.instance.getObjectByName("Etiquette_LP")
        this.chocolate = this.instance.getObjectByName("Chocolat_LP")

        const cf = this.app.debug.ui.addFolder('Aspect du chocolat')
        cf.open()

        if (this.hasLabel === false) {
            this.label.removeFromParent()
        }
        else {
            const pMaterial = new THREE.MeshPhysicalMaterial(this.label.material)
            this.label.material.dispose()
            this.label.material = pMaterial
    
            this.label.material.specularIntensity = 0.9
            this.label.material.clearcoat = 0.6
            this.label.material.clearcoatRoughness = 0.35
            
            const lf = this.app.debug.ui.addFolder('Aspect de l\'étiquette')
            lf.open()
            
            lf.add(this.label.material, 'specularIntensity').min(0).max(1).step(0.0001)
            lf.add(this.label.material, 'clearcoat').min(0).max(1).step(0.0001)
            lf.add(this.label.material, 'clearcoatRoughness').min(0).max(1).step(0.0001)
        }
        
        if (this.hasAlu === false) {
            this.alu.removeFromParent()
        }
        else {            
            this.alu.material.roughnessMap = this.app.assetManager.getItem('choco-roughness')
        }
        
        this.chocolate.material.color = new THREE.Color(0x705b44)
        this.chocolate.material.roughnessMap = this.app.assetManager.getItem('choco-roughness')
        
        this.chocolate.material.roughness = 0.8
        this.chocolate.material.metalness = 0.35

        Utils.addUIColor(cf, this.chocolate.material)
        cf.add(this.chocolate.material, 'roughness').min(0).max(1).step(0.0001)
        cf.add(this.chocolate.material, 'metalness').min(0).max(1).step(0.0001)
    }

    setupAnimation() {
        this.animMixer = new THREE.AnimationMixer(this.instance)

        const clip = THREE.AnimationClip.findByName(this.instance.children[0].animations, 'ChocolateRotation')
        this.clipAction = this.animMixer.clipAction(clip)
    }

    setLabelImage(imageName) {
        if (this.hasLabel === false) {
            console.warn("Set label image with no label")
            return
        }
        
        const texture = this.app.assetManager.getItem(imageName)
        if (texture === null) {            
            console.warn("Texture does not exist for chocolate label")
            return
        }
        
        const label = this.instance.getObjectByName("Etiquette_LP")
        label.material.map = texture

        // Fix texture orientation
        texture.center = new THREE.Vector2(0.5, 0.5)
        texture.rotation = Math.PI
        texture.wrapS = THREE.RepeatWrapping
        texture.wrapT = THREE.RepeatWrapping
        texture.repeat.x = -1
        texture.magFilter = THREE.LinearFilter
        texture.minFilter = THREE.LinearFilter
    }

    isLabelVisible() {
        const label = this.instance.getObjectByName("Etiquette_LP")
        return label.material.visible
    }

    hideLabel() {
        const label = this.instance.getObjectByName("Etiquette_LP")
        label.material.visible = false
    }

    showLabel() {
        const label = this.instance.getObjectByName("Etiquette_LP")
        label.material.visible = true
    }


    hideAlu() {
        const alu = this.instance.getObjectByName("Alu_LP")
        alu.material.visible = false
    }

    showAlu() {
        const alu = this.instance.getObjectByName("Alu_LP")
        alu.material.visible = true
    }

    isAluVisible() {
        const alu = this.instance.getObjectByName("Alu_LP")
        return alu.material.visible
    }

    disposeInstance() {
        if (this.hasAlu) {
            const alu = this.instance.getObjectByName("Alu_LP")
            alu.material.destroy()
        }

        if (this.hasLabel) {
            const label = this.instance.getObjectByName("Etiquette_LP")
            label.material.map.dispose()
            label.material.dispose()
        }

        const chocolate = this.instance.getObjectByName("Chocolat_LP")
        chocolate.material.normalMap.dispose()

        const resources = new Set()

		this.instance.traverse((object) => {
			if (object.isMesh) {
				resources.add(object.geometry)
				resources.add(object.material)
			}
		})

		for (const resource of resources) {
			resource.dispose()
		}
    }

    destroy() {
        this.disposeInstance()
        this.instance = null

        this.clipAction = null
        this.animMixer = null

        this.alu = null
        this.label = null
        this.chocolate = null

        this.app = null
    }
}