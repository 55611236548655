import * as THREE from 'three'
import { App } from '/App'
import { EventEmitter } from '/Utils/EventEmitter'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls'
import { ArcballControls } from 'three/examples/jsm/controls/ArcballControls'

export class Camera extends EventEmitter {
    constructor(controllerType) {
        super()

        this.app = null
        this.instance = null

        this.controllerType = typeof controllerType === "string" ? controllerType.toLowerCase() : null
        this.controller = null

        this.resizeHandlerBound = this.resizeHandler.bind(this)

        this.updateBound = this.update.bind(this)

        this.init()
    }

    init() {
        this.app = new App()

        this.instance = new THREE.PerspectiveCamera(45, this.app.renderSize.aspect, 0.001, 1000)
        this.fake = this.instance.clone()

        this.setupController()

        this.app.animationLoop.on('update', this.updateBound)
        
        this.app.renderSize.on('resize', this.resizeHandlerBound)
        
        // const cf = this.app.debug.ui.addFolder('Camera')
        // cf.open()

        // var cameraCfg = {
        //     controller: 'Orbit'
        // }

        // cf.add(cameraCfg, 'controller', [ 'Orbit', 'Trackball', 'Arcball' ]).onChange((value) => {
        //     this.setupController(value)
        // })

    }

    set position(pos) {
        this.instance.position.copy(pos)
        this.fake.position.copy(pos)
    }

    get orientation() {
        return this.fake.quaternion
    }

    setupController(controllerType) {
        controllerType = typeof controllerType !== "undefined" ? controllerType.toLowerCase() : this.controllerType

        if (this.controller !== null) {
            this.controller.dispose()
            this.controller = null
        }

        if (controllerType.includes("orbit")) {
            this.controller = new OrbitControls(this.fake, this.app.canvas)
            this.controller.listenToKeyEvents(window)
            this.controller.dampingFactor = 0.1    
        }
        
        else if (controllerType.includes("trackball")) {
            this.controller = new TrackballControls(this.fake, this.app.canvas)
        }
        
        else if (controllerType.includes("arcball")) {
            this.controller = new ArcballControls(this.fake, this.app.canvas, this.app.scene)
            this.controller.dampingFactor = 25
            this.controller.angularSpeed = 25
            // this.controller.setGizmosVisible(true)
            this.gizmoVisible = true
        }

        this.controller.enableDamping = true
    }
    
    update() {
        // console.log(this.fake.fov)
        // this.instance.fov = this.fake.fov
        // this.instance.updateProjectionMatrix()

        if (this.controller !== null) {
            this.controller.update()
        }
    }

    resizeHandler(info) {
        this.instance.aspect = info.aspect
        this.instance.updateProjectionMatrix()

        this.fovWidth = this.fovHeight * info.width / info.height
    }

    destroy() {
        this.app.animationLoop.off('update')
        this.updateBound = null
        
        this.app.renderSize.off('resize')
        this.resizeHandlerBound = null
        
        if (this.controller !== null) {
            this.controller.dispose()
            this.controller = null
        }

        this.instance = null
        this.fake = null

        this.app = null
    }
}