export default [

    // models
    {
        name: 'Chocolat_TEG_gltf',
        type: 'GLTF',
        path: 'Chocolat_WebGL/Chocolat_LP_teg01_gltf.gltf'
    },

    // textures
    {
        name: 'label_filmmaker',
        type: 'texture',
        path: 'Chocolat_WebGL/etiquette_Couleur_yann.png'
    },    
    
    {
        name: 'label_laposte',
        type: 'texture',
        path: 'Chocolat_WebGL/etiquette_Couleur_laposte.png'
    },    
    
    {
        name: 'label_exocorp',
        type: 'texture',
        path: 'Chocolat_WebGL/etiquette_Couleu_exocorp.png'
    },     
    
    {
        name: 'choco-roughness',
        type: 'texture',
        path: 'Chocolat_WebGL/slnqdcwc_4K_Roughness.jpg'
    },     
    
    // Envmap
    {
        name: 'handmade',
        type: 'exr',
        path: 'envmaps/gradient.exr',
    },

    {
        name: 'photostudio',
        type: 'exr',
        path: 'envmaps/brown_photostudio_02_2k_flat.exr'
    }
]
