// import modules
import { App } from './App'

import './scss/style.scss'

const canvas = document.querySelector('canvas.webgl')
const app = new App(canvas)

const header = document.querySelector("header")

app.assetManager.on("ready", (event) => {
    app.assetManager.off("progress")
    header.classList.add('hidden')
})

app.assetManager.on("progress", (event) => {
    header.classList.remove('hidden')
    const positive = parseInt(100 * event.progress).toString() + "%"
    const negative = parseInt(100 * (1 - event.progress)).toString() + "%"

    document.querySelector(".positive").style.width = positive
    document.querySelector(".negative").style.width = negative
    document.querySelectorAll(".progress-bar > div > span").forEach(span => { span.innerHTML = positive })
})
