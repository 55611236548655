import { Camera } from './Core/Camera.js'
import { Renderer } from './Core/Renderer.js'
import { AnimationLoop } from './Utils/AnimationLoop.js'
import { RenderSize } from './Utils/RenderSize.js'
import { AssetManager } from './Utils/AssetManager.js'
import { Debug } from './Utils/Debug.js'
import { EventEmitter } from './Utils/EventEmitter.js'
import { Utils } from './Utils/Utils.js'

import assets from "./assets.js"

import * as THREE from 'three'
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js'

import { Chocolate } from './World/Chocolate.js'

let instance = null

export class App extends EventEmitter {
    constructor(canvas) {
        super()

        if (instance !== null) {
            return instance
        }
        instance = this

        this.canvas = canvas

        this.debug = new Debug()
        
        this.animationLoop = new AnimationLoop()
        
        this.renderSize = new RenderSize()
        this.renderer = new Renderer()
        
        this.updateBound = this.update.bind(this)
        this.animationLoop.on('update', this.updateBound)

        this.animationLoop.start()

                        
        this.scene = new THREE.Scene()
        this.container = new THREE.Group()
        this.scene.add(this.container)

        this.setupCamera()
        this.addEventListeners()

        this.assetManagerReadyHandlerBound = this.assetManagerReadyHandler.bind(this)
        this.mouseWheelEventBound = this.onMouseWheelEvent.bind(this)
        // this.mouseWheelEventBound = this.onMouseWheelEvent

        this.assetManager = new AssetManager(assets)
        this.assetManager.on('ready', this.assetManagerReadyHandlerBound)
        this.assetManager.startLoading()        
    }

    onMouseWheelEvent(event) {
        this.camera.instance.position.z = Math.max(0.02, this.camera.instance.position.z + event.deltaY / 30000)
    }

    removeEventListeners() {
        this.canvas.removeEventListener('wheel', this.onMouseWheelEvent)
    }

    addEventListeners() {
        this.canvas.addEventListener('wheel', (event) => this.onMouseWheelEvent(event))
    }
    
    setupCamera() {
        this.camera = new Camera("orbit")
        this.camera.position = new THREE.Vector3(0, 0, 0.07)
    }

    setupLights() {
        const envmap = this.assetManager.getItem('photostudio')
        this.renderer.instance.toneMappingExposure = 1.5
 
        const ambient = new THREE.AmbientLight(0xffffff, 1)
        // this.scene.add(ambient)
        
        const dirLight = new THREE.DirectionalLight(0x658c8c, 0.6)
        dirLight.position.set(1, 3, 5)
        this.scene.add(dirLight)
        
        // const spot = new THREE.SpotLight(0xf0f0f0, 1.0, 0.5, THREE.MathUtils.degToRad(20), 0.5, 1.0)
        // spot.position.set(0,0.2,0)
        // spot.target.position.set(0,0,0)
        // const spotH = new THREE.SpotLightHelper(spot)

        const lf = this.debug.ui.addFolder('Lumières')
        // lf.open()

        // let lightCtrl

        // const lightCfg = {
        //     ambientColor : ambient.color.getHex(),
        
        //     envmapLighting: (value) => {
        //         if (this.scene.environment !== null)
        //         {
        //             this.scene.environment = null
        //             this.scene.add(ambient)
        //             this.scene.add(dirLight)
        //             // this.scene.add(spot, spot.target)
        //             lightCtrl.name("Activer envmap")
        //         }
        //         else {
        //             this.scene.environment = envmap
        //             this.scene.remove(ambient)
        //             this.scene.remove(dirLight)
        //             // this.scene.remove(spot, spot.target)
        //             lightCtrl.name("Activer lights")
        //         }
        //     }
        // }

        // lightCtrl = lf.add(lightCfg, 'envmapLighting').name("Toggle envmap / lights")
        
        // lf.add(ambient, 'intensity').min(0).max(1).step(0.01).name("Ambient")
        // Utils.addUIColor(lf, ambient) 
        
        lf.add(dirLight, 'intensity').min(0).max(1).step(0.01).name("Directional")
        Utils.addUIColor(lf, dirLight)

        // const environment = new RoomEnvironment(this.renderer.instance)
        // this.scene.environment = new THREE.PMREMGenerator(this.renderer.instance).fromScene(environment)

        this.scene.environment = envmap

        // this.scene.background = envmap
        // this.scene.backgroundBlurriness = 0.8
    }

    setupUI() {
        const cf = this.debug.ui.addFolder("Chocolat")
        cf.open()

        let labelCtrl, aluCtrl

        var chocolateCfg = {
            showHideLabel: () => {
                if (this.choc.isLabelVisible()) {
                    this.choc.hideLabel()
                    labelCtrl.name("Montrer l'étiquette")
                }
                else {
                    this.choc.showLabel()

                    if (!this.choc.isAluVisible()) {
                        this.choc.showAlu()
                    }

                    labelCtrl.name("Masquer l'étiquette")
                }
            },

            showHideAlu: () => {
                if (this.choc.isAluVisible()) {
                    this.choc.hideAlu()
                    aluCtrl.name("Montrer l'aluminium")
                }
                else {
                    this.choc.showAlu()
                    aluCtrl.name("Masquer l'aluminium")
                }
            },

            etiquette: 'ExoCorp',
            environment: 'Photostudio'
        }

        labelCtrl = cf.add(chocolateCfg, 'showHideLabel').name("Masquer l'étiquette")
        aluCtrl = cf.add(chocolateCfg, 'showHideAlu').name("Masquer l'aluminium")

        cf.add(chocolateCfg, 'etiquette', [ 'ExoCorp', 'LaPoste', 'Filmmaker' ]).onChange((value) => {
            const label = "label_" + value.toLowerCase()
            this.choc.setLabelImage(label)
        })

        cf.add(chocolateCfg, 'environment', [ 'Photostudio', 'Handmade' ]).onChange((value) => {
            value = value.toLowerCase()
            this.scene.environment = this.assetManager.getItem(value)
            this.renderer.instance.toneMappingExposure = 0.8
            if (value === 'photostudio') {
                this.renderer.instance.toneMappingExposure = 1.8
            }
        })
    }

    setupScene() {
        this.choc = new Chocolate()
        this.choc.setLabelImage("label_exocorp")
        this.container.add(this.choc.instance)
    }
    
    assetManagerReadyHandler(info) {
        this.setupScene()
        this.setupLights()
        this.setupUI()
    }

    update(info) {
        this.container.setRotationFromQuaternion(this.camera.orientation.conjugate())
        this.renderer.render(this.scene, this.camera.instance)
    }

    destroy() {
        this.animationLoop.off('update')
        this.updateBound = null

        this.removeEventListeners()
        this.mouseWheelEventBound = null
    
        this.assetManager.off('ready')
        this.assetManagerReadyHandlerBound = null
        this.assetManager.destroy()
        this.assetManager = null

        this.renderer.destroy()
        this.renderer = null

        this.camera.destroy()
        this.camera = null
    }
}