import * as Dat from 'dat-gui'
// import * as Stats from 'stats.js'
import { App } from '../App'

export class Debug {
    constructor() {

        // this.active = window.location.hash === '#debug'
        this.active = true

        this.app = null
        this.beforeUpdateHandlerBound = this.beforeUpdateHandler.bind(this)
        this.afterUpdateHandlerBound = this.afterUpdateHandler.bind(this)

        const ui = {
            add: () => { return ui },
            addColor: () => { return ui },
            addFolder: () => { return ui },
            min: () => { return ui },
            max: () => { return ui },
            open: () => { return ui },
            onChange: () => { return ui },
            step: () => { return ui },
            listen: () => { return ui },
            name: () => { return ui }
        } 
        this.ui = this.active ? null : ui
        this.stats = null

        if (this.active) {
            this.init()
        }
    }

    init() {
        this.app = new App()

        this.ui = new Dat.GUI({
            width: 350
        })

        // this.ui.close()

        // this.stats = new Stats()
        // this.stats.dom.height = '48px';
        // [].forEach.call(this.stats.dom.children, (child) => (child.style.display = ''));
        // document.body.appendChild(this.stats.dom)

        // const perfFolder = this.ui.addFolder('Performance');
        // const perfLi = document.createElement('li');
        // this.stats.dom.style.position = 'static';
        // perfLi.appendChild(this.stats.dom);
        // perfLi.classList.add('gui-stats');
        // perfFolder.__ul.appendChild(perfLi);


        // const guiWrap = document.createElement('div');
        // this.el.appendChild(guiWrap);
        // guiWrap.classList.add('gui-wrap');
        // guiWrap.appendChild(this.ui.domElement);
        // this.ui.open();


        this.app.on('beforeUpdate', this.beforeUpdateHandlerBound)
        this.app.on('afterUpdate', this.beforeUpdateHandlerBound)
    }

    update() {
        if (this.stats !== null) {
            this.stats.update()
        }
    }

    beforeUpdateHandler() {
        this.stats.begin()
    }

    afterUpdateHandler() {
        this.stats.end()
    }

    destroy() {
        this.app.off('beforeUpdate')
        this.app.off('afterUpdate')

        this.beforeUpdateHandlerBound = null
        this.afterUpdateHandlerBound = null

        this.ui.destroy()
        this.ui = null

        this.stats.dom.parentElement.removeChild(this.stats.dom)
        this.stats = null

        this.app = null
    }
}